#bg0 {
  color: aliceblue;
}

#appImg1,
#appImg2 {
  margin-right: 8px !important;
}

#bg6Div::-webkit-scrollbar {
  width: 7px;
}

#bg6Div::-webkit-scrollbar-thumb {
  height: 30%;
  background: #583eee;

  border-radius: 10px;
}

#bg6Div::-webkit-scrollbar-track {
  background: rgba(33, 122, 244, 0.1);
}

.fp-viewing-wev4 #mainHeader a,
.fp-viewing-wev5 #mainHeader a,
.fp-viewing-wev6 #mainHeader a,
.fp-viewing-wev7 #mainHeader a,
.fp-viewing-wev8 #mainHeader a,
.fp-viewing-wev4 #logoutBtn,
.fp-viewing-wev5 #logoutBtn,
.fp-viewing-wev6 #logoutBtn,
.fp-viewing-wev7 #logoutBtn,
.fp-viewing-wev8 #logoutBtn,
.fp-viewing-wev4 #menuBtn,
.fp-viewing-wev5 #menuBtn,
.fp-viewing-wev6 #menuBtn,
.fp-viewing-wev7 #menuBtn,
.fp-viewing-wev8 #menuBtn,
.fp-viewing-wev4 #mainHeader svg,
.fp-viewing-wev5 #mainHeader svg,
.fp-viewing-wev6 #mainHeader svg,
.fp-viewing-wev7 #mainHeader svg,
.fp-viewing-wev8 #mainHeader svg {
  color: #1e1e1e !important;
}

.fp-viewing-wev5 #mainHeader,
.fp-viewing-wev6 #mainHeader,
.fp-viewing-wev7 #mainHeader,
.fp-viewing-wev8 #mainHeader {
  background-color: white !important;
  opacity: 95% !important;
}

.fp-watermark {
  display: none;
}

/* scroll */
#fp-nav.remove {
  display: none !important;
}

#fp-nav ul li {
  width: 10px !important;
  margin-bottom: 25px !important;
  bottom: 20px !important;
}

#fp-nav ul li:nth-child(6),
#fp-nav ul li:nth-child(7),
#fp-nav ul li:nth-child(8) {
  display: none;
}

#fp-nav ul li a {
  width: 15px !important;
  height: 15px !important;
  margin: 0px !important;
}

#fp-nav ul li a.active::before {
  transform: scale(3.1) !important;
  opacity: 0.2 !important;
}

#fp-nav ul li a::before {
  position: absolute;
  clear: both;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  content: '';
  border-radius: 50%;
  transform: scale(1);
  opacity: 0;
  background-color: #fff;
  transition: all 0.3s;
}

.fp-viewing-wev2 #fp-nav ul li a::before,
.fp-viewing-wev4 #fp-nav ul li a::before {
  background-color: gray !important;
}

#fp-nav ul li a.active span:nth-child(2) {
  width: 8px !important;
  height: 8px !important;
  background-color: indigo !important;
  border: 1px solid white !important;
  box-shadow: 0px 0px 10px white;
}

#fp-nav ul li a span {
  background: none !important;
  border: 1px solid white !important;
  width: 8px !important;
  height: 8px !important;
  margin: 0 !important;
  opacity: 0.4;
}

.fp-viewing-wev2 #fp-nav ul li a span,
.fp-viewing-wev4 #fp-nav ul li a span {
  border: 1px solid gray !important;
}

/* slides */
.fp-bottom {
  bottom: 40px !important;
}

.fp-slidesNav ul li {
  width: auto !important;
  display: flex !important;
  margin: 0 !important;
}

.fp-slidesNav ul li a {
  width: 100px !important;
  margin-right: 2px !important;
}

.fp-slidesNav ul li a span:nth-child(1) {
  display: none !important;
}

/* .fp-slidesNav ul li a.active span:nth-child(2) {
  width: 100px !important;
  height: 3px !important;
  margin: 0px !important;
  left: 0 !important;
  border-radius: 0 !important;
  background: linear-gradient(to right, #2beede, #583eee);
} */

#bg4-1 {
  width: 100% !important;
}

/* first section slide */
.fp-viewing-wev0 .fp-slidesNav ul li:nth-child(1) a span:nth-child(2),
.fp-viewing-wev0-0 .fp-slidesNav ul li:nth-child(1) a span:nth-child(2),
.fp-viewing-wev0-1 .fp-slidesNav ul li:nth-child(2) a span:nth-child(2),
.fp-viewing-wev0-2 .fp-slidesNav ul li:nth-child(3) a span:nth-child(2) {
  width: 100px !important;
  height: 3px !important;
  margin: 0px !important;
  left: 0 !important;
  border-radius: 0 !important;
  background: linear-gradient(to right, #2beede, #583eee);
}

/* four section slide */
/* .fp-top {
  display: flex;
  padding-left: 60px;
  padding-top: 20%;
} */

.fp-viewing-wev3 .fp-slidesNav ul li:nth-child(1) a span:nth-child(2),
.fp-viewing-wev3-0 .fp-slidesNav ul li:nth-child(1) a span:nth-child(2),
.fp-viewing-wev3-1 .fp-slidesNav ul li:nth-child(2) a span:nth-child(2) {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: linear-gradient(to right, white, white);
}

#outer ul li:nth-child(2) a span:nth-child(2) {
  left: -30px !important;
}

#outer ul li a span {
  background-color: gray !important;
  width: 40px !important;
  height: 40px !important;
  margin: 0px !important;
  left: 0 !important;
  border-radius: 50% !important;
}

/* scroll animation */
.scrollDiv {
  position: fixed;
  z-index: 100;
  top: 80%;
  right: 17px;
  opacity: 0.3;
  color: #ced4da;
  font-size: x-small;
  text-align: end;
  width: 21px;
}

.scrollDiv {
  padding-top: 60px;
}

.scrollDiv span {
  position: absolute;
  top: 0;
  left: 100%;
  width: 17px;
  height: 37px;
  margin-left: -15px;
  border: 1px solid #ced4da;
  border-radius: 50px;
  box-sizing: border-box;
}
.scrollDiv span::before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: '';
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #ced4da;
  border-radius: 100%;
  -webkit-animation: sdb 2s infinite;
  animation: sdb 2s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.fp-viewing-wev2 .scrollDiv,
.fp-viewing-wev4 .scrollDiv,
.fp-viewing-wev5 .scrollDiv {
  color: black;
  opacity: 0.2;
}

.fp-viewing-wev2 .scrollDiv span,
.fp-viewing-wev4 .scrollDiv span,
.fp-viewing-wev5 .scrollDiv span {
  border: 1px solid black;
}

.fp-viewing-wev2 .scrollDiv span::before,
.fp-viewing-wev4 .scrollDiv span::before,
.fp-viewing-wev5 .scrollDiv span::before {
  background-color: black;
}

.fp-viewing-wev7 .scrollDiv {
  display: none;
}

/*  */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fp-viewing-wev0-0 #pulse,
.fp-viewing-wev0 #pulse {
  animation: fadeIn 2s ease-in-out;
}

/*  */
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* 애니메이션 속성 적용 */
.fp-viewing-wev4 #slide-in {
  animation: slideInLeft 2s ease-in-out;
}

/* SweetAlert2 Input 속성 */
.swal2-input:focus {
  border: 1px solid #d9d9d9 !important;
  outline: none !important;
  box-shadow: none !important;
}

.swal2-validation-message {
  color: black !important;
  font-weight: 500 !important;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 720px;
}

.video-container div {
  width: 100%;
}

.video-container iframe {
  width: 100%;
  height: 720px;
}
