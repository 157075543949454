body {
  font-family: 'Pretendard', sans-serif;
  color: #1e1e1e;
  font-size: 16px;
  word-break: keep-all;
}
.lang-ja {
  word-break: break-all;
}

.scroll-hide {
  -ms-overflow-style: none; /* IE and Edge */
}

.scroll-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.normal {
  font-weight: 400;
}
.bold {
  font-weight: 700;
}
.bolder {
  font-weight: 800;
}
.light {
  font-weight: 300;
}

::selection {
  color: #ffffff;
  background: #583eee;
}

.toastui-editor-contents {
  font-size: 16px !important;
  font-family: 'NanumSquare' !important;
}

.toastui-editor-contents li {
  display: flex;
}

.toastui-editor-ww-code-block:after {
  visibility: hidden !important;
}

.noScroll {
  overflow: hidden !important;
  height: auto;
}

input {
  outline: none;
  box-shadow: none;
}
input[type='radio'] {
  flex-shrink: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
