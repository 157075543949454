@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  /* src: url('/fonts/Pretendard-Bold.otf') format('woff2'), url('/fonts/Pretendard-Bold.otf') format('woff'),
    url('/fonts/Pretendard-Bold.otf') format('truetype');
     */
  src: url('/fonts/Pretendard-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  /* src: url('/fonts/Pretendard-SemiBold.otf') format('woff2'), url('/fonts/Pretendard-SemiBold.otf') format('woff'),
    url('/fonts/Pretendard-SemiBold.otf') format('truetype'); */
  src: url('/fonts/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  src: url('/fonts/Pretendard-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  src: url('/fonts/Pretendard-Regular.woff') format('woff');
}
